import * as React from 'react'
import cx from 'classnames'
import { ContentfulBlockHeader } from '../../data/block-header'

// @ts-ignore
import styles from './block-header.module.scss'
import Button from '../button/button'
import Hero, { HomeHeroProps } from '../section/home-header'

interface Props {
  query: ContentfulBlockHeader
}

const BUTTON_APPEARANCE = {
  default: 'default',
  inverse: 'secondary',
  hero: 'secondary',
} as const

function blockHeaderQueryToHomepageHeroProps(
  query: ContentfulBlockHeader
): HomeHeroProps {
  return {
    tagline: undefined,
    title: query.title,
    description: query.description.childMarkdownRemark.html,
    images: query.image
      ? [{ alt: query.image.title, sizes: query.image.sizes }]
      : [],
    onRequestScrollDown: undefined,
    button: query.cta || null,
    padabit: true,
  }
}

export default React.memo(function BlockHeader({ query }: Props) {
  if (query.theme === 'homepage') {
    const props = blockHeaderQueryToHomepageHeroProps(query)
    return <Hero {...props} />
  }
  return (
    <header
      id={query.anchor}
      className={cx(styles.block, {
        [styles.inverse]: query.theme === 'inverse',
        [styles.hero]: query.theme === 'hero',
      })}
      style={
        query.image && query.theme === 'hero'
          ? ({
              backgroundImage: `url(${query.image.sizes.src})`,
              '--block-header-scrim-color': query.heroImageScrimColor,
              '--block-header-scrim-opacity': query.heroImageScrimOpacity,
            } as any)
          : undefined
      }
    >
      <div className="container container--grid">
        <div className="container__row container__row--inset">
          <div className={cx(styles.block__heading, 'child-my-0')}>
            <h1 className="type-h-xml">{query.title}</h1>
          </div>
          <div
            className={cx(styles.block__description, 'type-p-xml child-my-0')}
            dangerouslySetInnerHTML={{
              __html: query.description.childMarkdownRemark.html,
            }}
          />
          {query.cta && (
            <div
              className={cx(styles.cta, {
                [styles.ctaOnDefault]: query.theme === 'default',
              })}
            >
              <Button
                appearance={BUTTON_APPEARANCE[query.theme || 'default']}
                href={query.cta.location}
                arrow
              >
                {query.cta.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  )
})
