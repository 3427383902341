import * as React from 'react'
import cx from 'classnames'
import { ContentfulBlockEditorial } from '../../data/block-editorial'
import { Button } from '../button'
import { Link } from '../link'
import { useDynamicTracking } from '../../hooks'
import styles from './block-editorial.module.scss'

interface Props {
  query: ContentfulBlockEditorial
}

export default function BlockEditorial({ query }: Props) {
  const { html } = query.content.childMarkdownRemark
  const [onTrackLink] = useDynamicTracking({
    action: 'Click side link',
    category: 'Editorial Block',
  })
  const [onTrackCTA] = useDynamicTracking({
    action: 'Click side CTA',
    category: 'Editorial Block',
  })
  return (
    <section
      id={query.anchor}
      className={cx(styles.block, 'container container--grid')}
    >
      <div className={styles.grid}>
        <div className={styles.grid__content}>
          <div
            className="wysiwyg wysiwyg--alternate child-my-0"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className={styles.grid__sidebar}>
          <aside className={styles.sidebar}>
            {!(query.links || []).length ? null : (
              <ul
                className={cx(styles.sidebar__links, 'list-reset type-d-p-md')}
              >
                {query.links.map(link => (
                  <li key={link.id}>
                    <Link
                      onClick={() => onTrackLink({ label: link.text })}
                      to={link.location}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {!query.cta ? null : (
              <Button
                size="small"
                arrow
                fill
                as={Link}
                to={query.cta.location}
                onClick={() => onTrackCTA({ label: query.cta.text })}
                appearance="secondary"
              >
                {query.cta.text}
              </Button>
            )}
          </aside>
        </div>
      </div>
    </section>
  )
}
