import * as React from 'react'
import cx from 'classnames'
import anime from 'animejs'
import { ContentfulBlockFaq } from '../../data/block-faq'
// @ts-ignore
import styles from './block-faq.module.scss'
import { useDynamicTracking } from '../../hooks'

const EVENT_LABEL_MAX_LENGTH = 39

interface Props {
  query: ContentfulBlockFaq
}

export default function BlockFaq({ query }: Props) {
  const [onTrack] = useDynamicTracking({
    action: 'Show answer',
    category: 'FAQ Block',
  })
  return (
    <section
      id={query.anchor}
      className={cx(styles.block, {
        [styles.white]: query.theme === 'alternate',
        [styles.blue]: query.theme === 'inverse',
      })}
    >
      <div className="container container--grid">
        <div
          className={cx(
            styles.block__grid,
            'container__row container__row--inset'
          )}
        >
          <h2 className={cx(styles.block__heading, 'type-sp-xl')}>FAQ</h2>
          {!query.description ? null : (
            <div
              className={cx(styles.block__description, 'child-my-0 type-p-md')}
              dangerouslySetInnerHTML={{
                __html: query.description.childMarkdownRemark.html,
              }}
            />
          )}
          <div className={styles.block__items}>
            {query.items.map(item => (
              <FaqToggle
                key={item.id}
                question={item.question}
                answer={item.answer}
                onClick={onTrack}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

function FaqToggle({ question, answer, onClick }) {
  const [expanded, setExpanded] = React.useState(false)
  const ref = React.useRef()
  const onToggleExpanded = () => {
    if (!expanded) {
      let label = question
      if (label.length > EVENT_LABEL_MAX_LENGTH) {
        label = label.slice(0, EVENT_LABEL_MAX_LENGTH) + '…'
      }
      onClick({ label })
    }
    setExpanded(!expanded)
  }

  React.useEffect(() => {
    if (!ref.current) {
      return // this _shouldn't_ happen
    }
    if (expanded) {
      anime({
        duration: 400,
        easing: 'easeOutSine',
        height: ref.current.scrollHeight,
        targets: ref.current,
      })
    } else {
      anime({
        duration: 400,
        easing: 'easeOutSine',
        height: 0,
        targets: ref.current,
      })
    }
  }, [expanded])

  return (
    <div className={styles.faq} aria-expanded={expanded}>
      <h3 className={cx(styles.faq__question, 'type-m-p-xxl')}>
        <button type="button" onClick={onToggleExpanded}>
          <span className={styles.faq__question__text}>{question}</span>
          <span role="img" className={styles.faq__question__icon} />
        </button>
      </h3>
      <div ref={ref} className={cx(styles.faq__answer, 'child-my-0 type-p-xl')}>
        <div
          dangerouslySetInnerHTML={{
            __html: answer.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  )
}
