import * as React from 'react'
import cx from 'classnames'
import { CoverImage } from '../image'
import { ContentfulBlockGrid } from '../../data/block-grid'
// @ts-ignore
import styles from './block-grid.module.scss'
import { useDynamicTracking } from '../../hooks'

interface Props {
  query: ContentfulBlockGrid
}

const FILTER_COLORS = {
  selected: ['color-white', 'bg-dark-navy'],
  themes: [
    ['color-dark-navy', 'bg-mint'],
    ['color-dark-navy', 'bg-n90'],
    ['color-dark-navy', 'bg-cookie'],
  ],
}

const getFiltersFromQuery = (query: ContentfulBlockGrid) => {
  let tags = {}
  ;(query.items || []).forEach(item =>
    (item.tags || []).forEach(tag => {
      tags[tag] = true
    })
  )
  return Object.keys(tags)
}

const toggle = (nextFilter, currentFilter) => {
  // toggling off the current value
  if (nextFilter === currentFilter) return null
  // or enable it in place of any old one
  else return nextFilter
}

const applyFilter = (items, filter) => {
  // if there's no filter, show all
  if (!filter) return items
  // if there's a filter, apply it
  return items.filter(item => {
    let match = false
    ;(item.tags || []).forEach(tag => {
      if (tag === filter) match = true
    })
    return match
  })
}

const getFilterThemeMap = (filters, themes) =>
  filters.reduce(
    (map, filter, idx) => ({
      ...map,
      [filter]: themes[idx % themes.length],
    }),
    {}
  )

export default function BlockGrid({ query }: Props) {
  const filters = getFiltersFromQuery(query)
  const [activeFilter, setActiveFilter] = React.useState(null)
  const [onTrack] = useDynamicTracking({
    action: 'Filter',
    category: 'Grid Block',
  })
  const onSelect = val => {
    const nextState = toggle(val, activeFilter)
    setActiveFilter(nextState)
    // track toggle on, not toggle off (null value)
    nextState && onTrack({ label: nextState })
  }
  const filterThemeMap = getFilterThemeMap(filters, FILTER_COLORS.themes)
  return (
    <section
      className={cx('container container--grid', styles.block)}
      id={query.anchor}
    >
      <div className="container__row container__row--inset">
        <h2 className={cx(styles.block__heading, 'type-sp-xl')}>
          {query.heading}
        </h2>
        {query.filters ? (
          <div className={styles.block__filters}>
            <GridFilters
              filters={filters}
              onSelect={onSelect}
              activeFilter={activeFilter}
              filterThemeMap={filterThemeMap}
            />
          </div>
        ) : null}
        <ul className={cx(styles.block__grid, styles.grid, 'list-reset')}>
          {applyFilter(query.items, activeFilter).map(item => (
            <li key={item.id} className={styles.grid__item}>
              <GridItemPerson
                person={item.person}
                tags={item.tags}
                filterThemeMap={filterThemeMap}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

function GridFilters({
  onSelect,
  filters,
  activeFilter,
  filterThemeMap,
  ...props
}) {
  return (
    <ul {...props} className={cx(styles.filters, 'list-reset')}>
      {filters.map((filter, idx) => (
        <li className={cx(styles.filters__item)} key={filter}>
          <GridFilter
            selected={filter === activeFilter}
            onClick={() => onSelect(filter)}
            className={cx(
              ...(filter === activeFilter
                ? FILTER_COLORS.selected
                : filterThemeMap[filter])
            )}
          >
            {filter}
          </GridFilter>
        </li>
      ))}
    </ul>
  )
}

function GridFilter({ selected, className, ...props }) {
  return (
    <button
      type="button"
      {...props}
      className={cx(styles.filter, className, 'type-p-xl')}
      aria-pressed={selected}
    />
  )
}

function GridItemPerson({ person, tags, filterThemeMap }) {
  const { image, name, company, title } = person
  return (
    <>
      <CoverImage ratio={251 / 287} {...image} />
      <div className={styles.person__mobile}>
        <h3 className="type-h3">{name}</h3>
        <p className="type-p-lg">
          {company}
          <br />
          {title}
        </p>
      </div>
      <div className={styles.person__desktop}>
        <h3 className="type-h5">{name}</h3>
        <p className="type-p-md">
          {company}
          <br />
          {title}
        </p>
      </div>
      <ul className={cx(styles.tags, 'list-reset')}>
        {(tags || []).map(tag => (
          <li
            key={tag}
            className={cx(
              styles.tag,
              ...(filterThemeMap[tag] ? filterThemeMap[tag] : []),
              'type-label-md'
            )}
          >
            {tag}
          </li>
        ))}
      </ul>
    </>
  )
}
