import * as React from 'react'
import cx from 'classnames'
import GatsbyImage from 'gatsby-image'

import { ContentfulBlockImage } from '../../data/block-image'

// @ts-ignore
import styles from './block-image.module.scss'

interface Props {
  query: ContentfulBlockImage
}

export default function BlockImage({ query }: Props) {
  return (
    <figure className={styles.block}>
      <GatsbyImage
        {...query.image}
        className={cx({
          [styles[query.height]]: query.height !== 'auto',
        })}
      />
    </figure>
  )
}
