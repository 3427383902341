import * as React from 'react'
import { graphql } from 'gatsby'
import { SEO } from '../components/seo'
import {
  BlockCTA,
  BlockEditorial,
  BlockFaq,
  BlockGrid,
  BlockHeader,
  BlockImage,
} from '../components/marketing-page'
import { ContentfulTemplateMarketingPage } from '../data/marketing-page'

interface Props {
  data: {
    page: ContentfulTemplateMarketingPage
  }
}

const BLOCK_BY_TYPENAME = {
  ContentfulBlockCallToAction: BlockCTA,
  ContentfulBlockEditorial: BlockEditorial,
  ContentfulBlockFaq: BlockFaq,
  ContentfulBlockGrid: BlockGrid,
  ContentfulBlockHeader: BlockHeader,
  ContentfulBlockImage: BlockImage,
}

export default function Page(props: Props) {
  const { content = [], seo } = props.data.page
  return (
    <>
      {!seo ? null : (
        <SEO
          title={seo.title}
          description={seo.description.description}
          image={seo.image.file.url}
          keywords={seo.keywords ? [seo.keywords] : null}
        />
      )}
      <article>
        {content.map(block => {
          const Element = BLOCK_BY_TYPENAME[block.__typename]
          if (!Element) return null
          return <Element key={block.id} query={block} />
        })}
      </article>
    </>
  )
}

export const query = graphql`
  query MarketingPage($slug: String!) {
    page: contentfulTemplateMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageFields
    }
  }
`
